@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.4);
  }
  100% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
}

@keyframes bellshake {
	0% { transform: rotate(0); }
	10% { transform: rotate(5deg); }
	20% { transform: rotate(-5deg); }
	30% { transform: rotate(4deg); }
	40% { transform: rotate(-4deg); }
	50% { transform: rotate(2deg); }
	60% { transform: rotate(-2deg); }
	70% { transform: rotate(1deg); }
	80% { transform: rotate(0); }
	100% { transform: rotate(0); }
}