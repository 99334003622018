.flex-center-row {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-center-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.flex-center-left-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.flex-center-left-row {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.flex-center-right-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.flex-space-between-start-row {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.flex-space-between-center-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-start-start-row {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.flex-center-right-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.content-container {
	width: 100%;
	height: 100%;
}

.content-column {
	width: 100%;
	height: 100%;
}

ngx-material-timepicker-container {
	.timepicker-overlay {
		z-index: 1001 !important;
	}
}