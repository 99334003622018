app-loading,
app-employees,
app-map,
app-driver-chat,
app-fleet {
	display: block;
	width: 100%;
	height: 100%;
}

app-drivers-sidebar,
app-driver-sidebar,
app-company-settings {
	height: 100%;
}

app-rect-button,
app-sidebar-group-item {
	width: 100%;
}

app-chat-container {
	height: 100%;
	max-height: 100%;
}